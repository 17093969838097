import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Container } from '@material-ui/core';

import CardWide from '../Cards/CardWide';
import Ribbon from '../Ribbon';
import Layout from '../Layout';
import CardHorizontal from '../Cards/CardHorizontal';
import SEO from '../SEO';

const SinglePageLayout = ({
  ribbon,
  textTop,
  textBottom,
  CardHorizontal1,
  CardHorizontal2,
  CardHorizontal3,
  seo,
}) => (
  <>
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        pathname={seo.pathname}
        location={seo.location}
      />
      <Ribbon
        fluid={ribbon.image}
        heading={ribbon.heading}
        pitchLine={ribbon.pitchLine}
      />
      <Container fixed className='mt-10x mb-10x'>
        <CardWide
          className='center-xs'
          headerElem='h2'
          headerTitle={textTop.header}
          data-sal='slide-left'
        >
          <>
            {textTop.inner &&
              textTop.inner.map((text, i) => <p key={i}>{text}</p>)}
            {textTop.textWithLink && textTop.textWithLink()}
          </>
          <></>
        </CardWide>
        <CardHorizontal
          img={CardHorizontal1.img}
          imgClass='img--pos img'
          imgAlt={CardHorizontal1.imgAlt}
          imgTitle={CardHorizontal1.imgTitle}
          containerClass={classNames(
            'cards',
            !CardHorizontal1.noSpace && 'mt-10x ',
          )}
          textFirst={CardHorizontal1.textFirst}
          data-sal='slide-right'
        >
          <>
            <h3 className={classNames(CardHorizontal1.noSpace && 'pt-4x')}>
              {CardHorizontal1.header}
            </h3>
            {CardHorizontal1.inner && <p>{CardHorizontal1.inner}</p>}
            {CardHorizontal1.textWithLink && (
              <>{CardHorizontal1.textWithLink()}</>
            )}
          </>
        </CardHorizontal>
        {CardHorizontal2 && (
          <CardHorizontal
            img={CardHorizontal2.img}
            imgClass='img--pos img'
            imgAlt={CardHorizontal2.imgAlt}
            imgTitle={CardHorizontal2.imgTitle}
            containerClass={classNames(
              'cards',
              !CardHorizontal2.noSpace && 'mt-10x ',
            )}
            textFirst={CardHorizontal2.textFirst}
            data-sal='slide-left'
          >
            <>
              <h3 className={classNames(CardHorizontal2.noSpace && 'pt-4x')}>
                {CardHorizontal2.header}
              </h3>
              {CardHorizontal2.inner && <p>{CardHorizontal2.inner}</p>}
              {CardHorizontal2.textWithLink && (
                <>{CardHorizontal2.textWithLink()}</>
              )}
            </>
          </CardHorizontal>
        )}
        {CardHorizontal3 && (
          <CardHorizontal
            img={CardHorizontal3.img}
            imgClass='img--pos img'
            imgAlt={CardHorizontal3.imgAlt}
            imgTitle={CardHorizontal3.imgTitle}
            containerClass={classNames(
              'cards',
              !CardHorizontal3.noSpace && 'mt-10x ',
            )}
            textFirst={CardHorizontal3.textFirst}
            data-sal='slide-right'
          >
            <>
              <h3 className={classNames(CardHorizontal3.noSpace && 'pt-4x')}>
                {CardHorizontal3.header}
              </h3>
              {CardHorizontal3.inner && <p>{CardHorizontal3.inner}</p>}
              {CardHorizontal3.textWithLink && (
                <>{CardHorizontal3.textWithLink()}</>
              )}
            </>
          </CardHorizontal>
        )}
        {textBottom && (
          <CardWide
            className='center-xs mt-10x'
            headerElem='h2'
            headerTitle={textBottom.header}
            data-sal='slide-bottom'
          >
            <>
              {textBottom.inner &&
                textBottom.inner.map((text, i) => <p key={i}>{text}</p>)}
              {textBottom.textWithLink && textBottom.textWithLink()}
            </>
          </CardWide>
        )}
      </Container>
    </Layout>
  </>
);

SinglePageLayout.propTypes = {
  ribbon: PropTypes.object,
  textTop: PropTypes.object,
  textBottom: PropTypes.object,
  CardHorizontal1: PropTypes.object.isRequired,
  CardHorizontal2: PropTypes.object,
  CardHorizontal3: PropTypes.object,
  seo: PropTypes.object.isRequired,
};

export default SinglePageLayout;
