// eslint-disable-next-line import/prefer-default-export
export const menu = [
  {
    name: 'wandafwerking',
    hasSubMenu: true,
    subMenu: ['glad-pleisterwerk', 'sierpleister', 'behang-klaar'],
    mobileSubMenu: [
      'wandafwerking',
      'glad-pleisterwerk',
      'sierpleister',
      'behang-klaar',
    ],
  },
  {
    name: 'plafondafwerking',
    hasSubMenu: true,
    subMenu: ['glad-pleisterwerk', 'schuurplafond', 'plafond-plint'],
    mobileSubMenu: [
      'plafondafwerking',
      'glad-pleisterwerk',
      'schuurplafond',
      'plafond-plint',
    ],
  },
  {
    name: 'speciaal-stukwerk',
    hasSubMenu: true,
    subMenu: ['beton-cire'],
    mobileSubMenu: ['speciaal-stukwerk', 'beton-cire'],
  },
  {
    name: 'contact',
    hasSubMenu: false,
  },
];
