import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';

import { StyledDesktopNav } from './StyledMobielNav';
import HamburgerMenu from './HamburgerMenu';
import { menu } from '../menuData';
import SingleListItem from './SingleListItem';
import { useFavicon } from '../../../hooks/get-favicon';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

const MobileNav = ({ location }) => {
  const classes = useStyles();
  const [state, setState] = useState(false);
  const [scroll, setScroll] = useState(0);
  const [favicon] = useFavicon();

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scroll]);

  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const customStyle = {
    style: {
      background: 'linear-gradient(to right, #fa0000 0%, #eb4b48 100%)',
    },
  };

  const toggleDrawer = open => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState(open);
  };

  const sideList = () => (
    <div
      className={classes.list}
      role='presentation'
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <>
          <div className='mobile__image mb-2x'>
            <Link to='/'>
              <Img
                fadeIn={false}
                fixed={favicon.childImageSharp.fixed}
                alt='M.Pals Stukadoors'
              />
            </Link>
          </div>
          {menu.map((text, index) => (
            <SingleListItem
              text={text}
              key={index}
              close={toggleDrawer}
              location={location}
              setState={setState}
            />
          ))}
        </>
      </List>
    </div>
  );

  return (
    <StyledDesktopNav scroll={scroll}>
      <AniLink fade to='/'>
        <Img
          fixed={favicon.childImageSharp.fixed}
          alt='M.Pals Stukadoors'
          className='ml-4x'
        />
      </AniLink>
      <Button
        role='button'
        aria-hidden='true'
        onClick={toggleDrawer(true)}
        disableRipple
      >
        <HamburgerMenu active={state} />
      </Button>
      <SwipeableDrawer
        PaperProps={customStyle}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        anchor='left'
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {sideList('left')}
      </SwipeableDrawer>
    </StyledDesktopNav>
  );
};

MobileNav.propTypes = {
  location: PropTypes.object,
};

export default MobileNav;
