import React, { useState } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { capitalize } from '../../../helpers/capitalize';
import { StyledSingleListItem } from './StyledSingleListItem';

const SingleListItem = ({ text, location, setState }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOnClick = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const renderLink = linkText =>
    linkText.hasSubMenu ? (
      <span
        className={classNames(
          'text-size text--bold',
          location.pathname.includes(linkText.name) && 'text--underline',
        )}
      >
        {capitalize(linkText.name)}
      </span>
    ) : (
      <AniLink
        fade
        className='text--white text--bold'
        to={`/${linkText.name}`}
        onClick={() => setState(false)}
      >
        <span className='text-size'>{capitalize(linkText.name)}</span>
      </AniLink>
    );

  return (
    <StyledSingleListItem>
      <ListItem button key={text.name} onClick={e => handleOnClick(e)}>
        <ListItemIcon>
          {text.hasSubMenu ? (
            <i className='material-icons'>keyboard_arrow_down</i>
          ) : (
            <i className='material-icons'>chevron_right</i>
          )}
        </ListItemIcon>
        <ListItemText
          className='text--normal text-size text--white'
          primary={renderLink(text)}
        />
      </ListItem>
      <div className='trans'>
        {text.hasSubMenu &&
          text.mobileSubMenu.map((subMenu, i) => (
            <AniLink
              fade
              className='text--white text--normal'
              to={i === 0 ? `/${subMenu}` : `/${text.name}/${subMenu}`}
              key={i}
              onClick={() => setState(false)}
            >
              <ListItem
                className={classNames(
                  !isOpen && 'hidden',
                  'text--normal text--white text-size',
                )}
                button
                style={{ paddingLeft: '36px' }}
              >
                <ListItemIcon>
                  <i className='material-icons '>subdirectory_arrow_right</i>
                </ListItemIcon>
                <ListItemText
                  primary={capitalize(i === 0 ? 'overzicht' : subMenu)}
                  className='text-size'
                />
              </ListItem>
            </AniLink>
          ))}
      </div>
    </StyledSingleListItem>
  );
};

SingleListItem.propTypes = {
  text: PropTypes.object,
  location: PropTypes.object,
  setState: PropTypes.func,
};

export default SingleListItem;
