/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledRibbon = styled.div`
  position: relative;
  top: -110px;
  margin-bottom: -110px;

  .heading {
    position: relative;

    &__image {
      height: 450px;
      width: 100%;
      object-fit: cover;

      @media screen and (min-width: 960px) {
        height: 500px;
      }
    }

    &__text {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .heading__container {
        padding: 20px 30px;
        background-color: rgba(255, 255, 255, 0.7);
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
          0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        border-radius: 4px;

        h1 {
          font-weight: bold;
          font-size: 40px;

          i {
            font-size: 45px;
          }

          @media screen and (min-width: 960px) {
            font-size: 60px;
          }
        }
      }
    }

    &__button {
      background-color: #fa0000;
      color: white;

      i {
        color: white;
      }

      &:hover {
        background-color: white;
        color: #fa0000;

        i {
          color: #fa0000;
        }
      }
    }
  }

  .contact {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    &__container {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .contact {
        &__card {
          padding: 30px 20px;
          max-height: fit-content;
          background-color: rgba(255, 255, 255, 0.7);

          h2,
          p {
            color: #fa0000;
          }
        }

        &__button {
          background-color: #fa0000;
          color: white;
        }
      }
    }
  }
`;
