import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import classNames from 'classnames';

import { Grid } from '@material-ui/core';

const CardHorizontal = ({
  children,
  img,
  imgClass,
  imgAlt,
  imgTitle,
  containerClass,
  textFirst,
  ...props
}) => (
  <>
    <Grid container className={containerClass} {...props}>
      <Grid
        item
        xs={12}
        md={6}
        className={classNames(textFirst ? 'first-xs' : '')}
      >
        <div className='h100'>
          <Img
            className={imgClass}
            imgStyle={{ objectFit: 'contain' }}
            fluid={img}
            alt={imgAlt}
            title={imgTitle}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        className={classNames(textFirst ? 'first-sm' : 'pl-sm-4x')}
      >
        <div className='card card--transparent mb-0'>
          <div className='card__body'>{children}</div>
        </div>
      </Grid>
    </Grid>
  </>
);

CardHorizontal.propTypes = {
  children: PropTypes.node,
  img: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  imgClass: PropTypes.string,
  imgAlt: PropTypes.string,
  imgTitle: PropTypes.string,
  containerClass: PropTypes.string,
  textFirst: PropTypes.bool,
};

export default CardHorizontal;
