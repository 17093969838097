import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { useStaticQuery, graphql } from 'gatsby';

import { Container, Grid, Button } from '@material-ui/core';

import SocialIcons from '../SocialIcons';
import { StyledFooter } from './StyledFooter';

const Footer = () => {
  const { footerImg, favicon } = useStaticQuery(graphql`
    query {
      footerImg: file(relativePath: { eq: "palsie/glad4.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      favicon: file(relativePath: { eq: "favicon.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <StyledFooter className='footer' img={footerImg.childImageSharp.fluid.src}>
      <div className='footer__image'></div>
      <Container fixed className='footer__content'>
        <Grid container>
          <Grid item xs={12} md={4} className='first-sm'>
            <h2>M.Pals Stukadoors</h2>
            <p className='mt-2x'>
              Bij M.Pals Stukadoor staat kwaliteit centraal. Met onze zeer
              ervaren werknemers streven wij er naar om de beste kwaliteit te
              garanderen.
            </p>
            <div className='social-icons'>
              <SocialIcons className='mt-8x mt-md-4x' />
            </div>
            <ul className='mt-4x footer__extra hidden-xs hidden-sm'>
              <li>
                <p>M.Pals Stukadoors - 2020</p>
              </li>
              <li>
                <p>|</p>
              </li>
              <li>
                <p>© Derow</p>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} md={4} className='first-xs'>
            <div className='center-xs mt-4x mb-4x mt-md-0 mb-md-0'>
              <Img
                fixed={favicon.childImageSharp.fixed}
                objectFit='cover'
                className='mt-4x mt-md-0'
                alt='E S I - Installatietechniek'
              />
            </div>
          </Grid>
          <Grid item xs={12} md={4} className='mt-4x mt-sm-0'>
            <h2>Neem direct contact op</h2>
            <p className='mt-2x'>
              Wilt u een offerte aanvragen of meer informatie?
            </p>
            <p>
              M.Pals Stukadoors <br />
              <a href='mailto:m.palsstukadoors@hotmail.com'>
                m.palsstukadoors@hotmail.com
              </a>{' '}
              <br />
              06-29575549 <br />
              KVK: 71629130 0000 <br />
              BTW: NL002327161B58
            </p>

            <div className='between-xs flex-row  w100'>
              <div className='hidden-sm hidden-md hidden-lg w100'>
                <a href='tel:0642090304'>
                  <Button
                    role='button'
                    variant='contained'
                    className='btn heading__button mt-4x '
                  >
                    <i className='material-icons mr-1x'>phone</i>Krijg advies op
                    maat
                  </Button>
                </a>
              </div>
              <AniLink fade to='/contact' className='hidden-xs'>
                <Button
                  role='button'
                  variant='contained'
                  className='btn heading__button mt-4x '
                >
                  <i className='material-icons mr-1x'>arrow_right</i>Kom in
                  contact
                </Button>
              </AniLink>
            </div>
            <ul className='mt-4x footer__extra hidden-md hidden-lg'>
              <li>
                <p>M.Pals Stukadoors - 2020</p>
              </li>
              <li>
                <p>|</p>
              </li>
              <li>
                <AniLink fade to='/privacy'>
                  <p>Privacy?</p>
                </AniLink>
              </li>
              <li>
                <p>|</p>
              </li>
              <li>
                <p>© Derow</p>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
