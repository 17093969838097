/* eslint-disable react/no-this-in-sfc */
import React, { useEffect, useState } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Img from 'gatsby-image/withIEPolyfill';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import { StyledDesktopNavigation } from './StyledDesktopNavigation';
import { capitalize } from '../../../helpers/capitalize';
import { removeDash } from '../../../helpers/removeDash';
import { menu } from '../menuData';
import { useFavicon } from '../../../hooks/get-favicon';

const useStyles = makeStyles(() => ({
  container: {
    display: 'inline-flex',
    justifyContent: 'space-between',
  },
}));

const DesktopNavigation = ({ location }) => {
  const classes = useStyles();
  const [scroll, setScroll] = useState(0);
  const [, logo] = useFavicon();

  useScrollPosition(
    // eslint-disable-next-line no-unused-vars
    ({ prevPos, currPos }) => {
      const isVisible = currPos.y < -15;

      if (isVisible) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    },
    [scroll],
  );

  useEffect(() => {
    const triggers = document.querySelectorAll('.links > li');
    function handleEnter() {
      this.classList.add('trigger-enter');
      setTimeout(
        () =>
          this.classList.contains('trigger-enter') &&
          this.classList.add('trigger-enter-active'),
        50,
      );
    }
    function handleLeave() {
      this.classList.remove('trigger-enter', 'trigger-enter-active');
    }
    triggers.forEach(trigger =>
      trigger.addEventListener('mouseenter', handleEnter),
    );
    triggers.forEach(trigger =>
      trigger.addEventListener('mouseleave', handleLeave),
    );
    return () => {
      triggers.forEach(trigger =>
        trigger.removeEventListener('mouseenter', handleEnter),
      );
      triggers.forEach(trigger =>
        trigger.removeEventListener('mouseleave', handleLeave),
      );
    };
  }, []);

  return (
    <StyledDesktopNavigation className='nav' scroll={scroll}>
      <Container fixed className={classes.container}>
        <AniLink fade to='/' className='logo__container'>
          <Img
            fadeIn={false}
            fluid={logo.childImageSharp.fluid}
            objectFit='cover'
            className='mt-md-0 company__logo'
            alt='M.Pals Stukadoors'
          />
        </AniLink>
        <ul className='links'>
          {menu.map((item, i) => (
            <li key={`${item}-${i}`} className={classNames(i === 0 && 'ml-2x')}>
              <AniLink
                fade
                to={`/${item.name}`}
                className={classNames(
                  'links__item',
                  location.pathname.includes(item.name) && 'active',
                )}
              >
                {capitalize(item.name)}
                <i className='material-icons'>chevron_right</i>
              </AniLink>

              {item.hasSubMenu && (
                <ul className='dropdown courses'>
                  <span className='arrow' />
                  {item.subMenu.map((top, index) => (
                    <li key={`${top}-${index}`}>
                      <AniLink fade to={`/${item.name}/${top}`}>
                        {capitalize(removeDash(top))}
                        <i className='material-icons'>chevron_right</i>
                      </AniLink>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </Container>
    </StyledDesktopNavigation>
  );
};

DesktopNavigation.propTypes = {
  location: PropTypes.object,
};

export default DesktopNavigation;
